.ramalan-card {
  background: #e4efe9;
  text-align: center;
  width: 11vh;
  height: 13vh;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.ramalan-card-header {
  width: 100%;
  font-size: 1.5vh;
  padding: 3%;
  border-radius: 12px 12px 0 0;
  background: #2c3631;
}

.ramalan-card-body {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ramalan-card-body > div {
  width: 4vh;
  height: 4vh;
}

.ramalan-card-body p {
  font-size: 1.7vh;
  margin-top: -5%;
}

.ramalan-card-footer {
  width: 100%;
  padding-bottom: 10%;

  font-size: 1.2vh;
  font-weight: bold;
  white-space: nowrap;
}
