:root {
  --background: #f5f5f5;
  --background-secondary: #f9f9f9;
  --text: #363636;
  --text-secondary: #48514c;
  --accent-primary: #66b05a;
  --accent-secondary: #2d3832;
}

@font-face {
  font-family: "DM Sans";
  src: url("../public/assets/font/DM_Sans/DMSans-Italic-VariableFont_opsz\,wght.ttf") format("truetype");
  src: url("../public/assets/font/DM_Sans/DMSans-VariableFont_opsz\,wght.ttf") format("truetype");
}

#app {
  font-family: "DM Sans", sans-serif;
  color: var(--text);
  /* background: var(--background); */
  background: black;
  min-width: 100vw;
  max-width: 480px;
  min-height: 100vh;
  margin: auto;
}

.white-text {
  color: var(--background);
}

button {
  -webkit-tap-highlight-color: transparent;
}