.custom-confirm-button {
  border-radius: 30px;
  background: var(--accent-secondary);
}

.custom-cancel-button {
  border-radius: 30px;
  border: solid 2px var(--accent-secondary);
  background: transparent;
  color: var(--accent-secondary);
}

.custom-image-swal {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 2px var(--accent-secondary);
  object-fit: cover;
}

.custom-input-swal {
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  width: 85%;
}

.custom-input-swal:first-of-type {
    margin-bottom: 3%;
    margin-top: 3%;
}
