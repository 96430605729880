.indikator-list {
  align-items: center;
}

.indikator-list .icon-wrapper {
  border-radius: 50%;
  background: white;
  width: 25px;
  height: 25px;
  padding: 4%;
  margin-right: 7%;
}

.no-padding {
  padding: 1% !important;
}

.indikator-list .text-wrapper {
  flex-direction: column;
  text-align: start;
  font-size: 1.8vh;
  font-weight: bold;
}

.indikator-list .text-wrapper p:first-child {
  font-size: 0.82vh;
  font-weight: normal;
  margin-bottom: -5%;
}
